var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from 'react';
import styled from 'styled-components';
var AlertContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: green;\n  color: #fff;\n  padding: 1em;\n"], ["\n  background-color: green;\n  color: #fff;\n  padding: 1em;\n"])));
var Alert = function (_a) {
    var message = _a.message;
    return (React.createElement(AlertContainer, null, message));
};
export default Alert;
var templateObject_1;
