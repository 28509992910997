import React from 'react';
import ReactDOM from 'react-dom/client';
import Alert from './Alert.tsx';

const App = (props) => {
  return (
    <div>
      Hello, React App!
      <Alert message="success" />
    </div>
  );
};

const reactRoot = ReactDOM.createRoot(document.getElementById('react-root'));
if (reactRoot) {
  reactRoot.render(<App />);
} else {
  console.log('No root element found');
}
